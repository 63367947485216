<template>
  <div class="register">
    <h2>用户注册</h2><br>
    <p>可以免费注册的名额是：
      <span style="color: red">{{ registerNums }}个</span>
    </p>
    <div v-if="registerFlag">
        <b-input
        id="inline-form-input-name"
        :class="checkFlag ? 'isok': 'isfalse'"
        placeholder="请输入用户名(3位以上英文 或 英文加数字)"
        v-model="username"
        @keyup="checkuser"
      ></b-input>
      <b-input
        id="inline-form-input-password"
        type="password"
        :class="checkFlag2 ? 'isok': 'isfalse'"
        @keyup="checkpassword"
        placeholder="请输入用户密码(6位以上)" v-model="password"
      ></b-input>

      <div class="loginbtn">
        <b-button variant="outline-primary" @click="register">注册</b-button>
      </div>
    </div>

    <!--不能注册时显示-->
    <div class="noregister" v-if="noRegisterFlag">
      <p>没有免费注册名额了！</p><br>
      <p>或者 --> <a target="_blank" href="https://signup.hive.io/">前去 hive 注册</a></p>
    </div>

    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
  </div>
</template>

<script>
  import Loading from './Loading'
  export default {
    name: "Register",
    data() {
      return {
        registerNums: '',
        username:'',
        password:'',
        checkFlag: true,
        checkFlag2: true,
        registerFlag: true,
        noRegisterFlag: false,
        isLoading: false,
      }
    },
    methods:{
      async register(){
        this.registerFlag = false
        this.isLoading = true
        let username = this.username
        let password = this.password
        if(username.length < 3 || password.length < 6) {
          alert("用户名或密码长度不够！")
          this.isLoading = false
          this.registerFlag = true
          return
        }

        let ac = await this.client.database.call('lookup_account_names', [[username]])
        if(ac[0] !== null){
            this.isLoading = false
            this.registerFlag = true
            alert("用户名已存在！")
          }else{
            console.log(234, '开始注册')
            // let res = await this.create_discounted_account(username, password)

            this.axios.request({
              method: 'post',
              url: 'https://www.steemjiang.com:3001/register',
              data:{
                username:this.username,
                password:this.password
              }
            })
            .then( (arg) => {
              this.isLoading = false 
              console.log(111,arg)  
              console.log(222,arg.data) 

              if(arg.data === "ok"){
                localStorage.setItem('steemjiangregister', 'steemjiang')
                alert("恭喜！steem新帐户创建成功！")
                this.$router.push({path: '/login'})
              }else{
                alert("错误！新帐户创建失败！\n可能免费注册额度已用完！\n具体原因如下：\n" + arg.data)
                this.registerFlag = true
              }
             })
            .catch((error) => {
              console.log(333,error)
              alert("错误！\n" + error)
            })
            
          }
      },
      checkuser(){
        let that = this
        let username = this.username
        if(username.length < 3){
          return this.checkFlag=false
        }
        async function main(){
          let ac = await that.client.database.call('lookup_account_names', [[username]])
          if(ac[0] !== null){
            console.log("1111, 用户名已存在！")
            that.checkFlag = false
          }else{
            console.log(234567, '开始注册')
            that.checkFlag = true
          }
        }
        main()
      },
      checkpassword(){
        let password = this.password
        if(password.length < 6){
          return this.checkFlag2=false
        }else{
          this.checkFlag2 = true
        }
      },
    },

    components: {
      Loading
    },

    mounted() {
      //如果不是第一次注册
      if(localStorage.getItem('steemjiangregister') !== null){
        alert("您已注册新帐户，请不要多次注册！\n留点机会给别人吧！")
        this.$router.push({path: '/login'})
      }

      //注册前先查询下是否有免费名额
      let account = 'lemooljiang'
      this.steem.api.getAccounts([account], (err, result) => {
        // let num = result[0].pending_claimed_accounts
				let num = 0
        this.registerNums = num
        if(num === 0){
          //没名额，则不能注册
          this.registerFlag = false
          this.noRegisterFlag = true
        }
      })

    }


  }
</script>

<style scoped>

  .register{
    margin: 4rem 4rem 18rem 14rem;

  }
  .user{
    margin-top: 1.5rem;
    width: 50%;
  }
  .isok{
    margin-top: 1.5rem;
    width: 50%;
    /*background-color: chartreuse;*/
  }
  .isfalse{
    margin-top: 1.5rem;
    width: 50%;
    background-color: crimson;
  }

  .loginbtn{
    margin-top: 1.5rem;
  }
</style>

