<template>
  <div id="maintop">
    <div  v-if="flag">
    <b-input
      id="inline-form-input-name"
      class="mb-2 mr-sm-2 mb-sm-0 title"
      placeholder="请输入标题" v-model="title"
    ></b-input>

    <div id="main">
          <mavon-editor v-model="body" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
    </div>

    <br>
    <b-input
      class="mb-2 mr-sm-2 mb-sm-0"
      placeholder="tags  (输入英文标签，以空格分隔)" v-model="tags"
    ></b-input>

    <br>
    <b-form-group label="发布到网络：">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="selected"
        :options="options"
        name="flavour-1"
      ></b-form-checkbox-group>
    </b-form-group>

    <div class="btn">
      <b-button variant="outline-primary" @click="post">提交</b-button>
    </div>

    </div>
    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>

  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
        name: "Post",
      data() {
        return {
          db:{},
          body: '',
          title: '',
          tags: '',
          selected: ['steem', 'hive'], // Must be an array reference!
          options: [
            { text: 'Steem', value: 'steem' },
            { text: 'Hive', value: 'hive' }
          ],

          isLoading: false,
          flag: true
        }
      },

      methods: {
        async post(){
            this.flag = false
            this.isLoading = true
            let wif = this.$store.state.steempassword
            let hivewif = this.$store.state.password

            let parentAuthor = ''
            let parentPermlink = 'hive-180932'
            let parentPermlinkHive = 'hive-105017'
            // let parentPermlink = this.tags.trim().split(/\s+/)[0]
            let author = this.$store.state.username
            let permlink = this.getstr()
            let title = this.title
            let body = this.body
            let tags = this.tags.trim().split(/\s+/)
            let jsonMetadata = {"tags": tags, "dapp": "larkBlog", "format": "markdown"}
            let that = this

            //发布到本地数据库
            //把数据拼成一个JSON
            let post = {
              "title": title,
              "author": author,
              "category": 'hive-105017',
              "permlink": permlink,
              "body": body,
              "created": new Date().toISOString().split('.')[0],
              "json_metadata": JSON.stringify(jsonMetadata)
            }
          let db = this.$store.state.db
          let tableName = this.$store.state.username

          async function main(){
            await that.saveOneToDb(post, db, tableName)
            //发布到steem上
            // await that.postToSteemPro(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)
            if(that.selected.includes('steem')){
              that.postToSteem(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)
            }
  
            // //发布到hive上
            if(that.selected.includes('hive')){
              that.postToHive(hivewif, parentAuthor, parentPermlinkHive, author, permlink, title, body, jsonMetadata)
            }
            
            let posts = await that.getAllPosts(db)
            that.$store.commit('savePosts', posts.reverse())
            that.$router.push({path: '/blog/'+permlink})
          }
          main()
          },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }
      },

      components: {
        Loading
      },

      mounted() {
        let _this = this
        async function main(){
          if(_this.$store.state.username){
            //如果已登录
            if(_this.$store.state.posts == false){
              //第一次打开页面或是刷新
              let db = await _this.initDatabase()
              _this.$store.commit('saveDB',db)
              let posts = await _this.getAllPosts(db)
              _this.$store.commit('savePosts', posts.reverse())
            }
          }


        }
        main()
      }
    }
</script>

<style scoped>
  #maintop{
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  #main{
    height: 40rem;
  }
  .title{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem !important;
    width: 60%;
  }
</style>
