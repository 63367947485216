<template>
  <div id="content">
    <h1>{{ post.title }}</h1>

    <p class="posttime">@{{ post.author }} 　{{ post.created | formatTime}}</p>

    <mavon-editor
     :value="post.body + '<br>' + '<br>' + tags + JSON.parse(post.json_metadata).tags.join('　')"
    :subfield = "prop.subfield"
    :defaultOpen = "prop.defaultOpen"
    :toolbarsFlag = "prop.toolbarsFlag"
    :editable="prop.editable"
    :scrollStyle="prop.scrollStyle"
    ></mavon-editor>

    <br>


    <span v-if="$store.state.username" class="newreply">
      <b-button variant="outline-primary" @click="reply">回复</b-button>
    </span>

    <span v-if="flag">
      已收藏
    </span>
    <span v-else >
      <b-button variant="outline-primary" @click="addCollection">收藏</b-button>
    </span>


    <div v-if="replyFlag">
      <div id="main">
        <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
      </div>

      <b-button variant="outline-primary" @click="replypost">提交</b-button>
      <b-button variant="outline-primary" @click="clear">取消</b-button>
    </div>

    <OtherReply :otherreply="areply"></OtherReply>


  </div>
</template>

<script>

  import OtherReply from './OtherReply'
    export default {
      name: "Feed",
      data() {
        return {
          db:{},
          post: {},
          data:'',
          flag:localStorage.getItem(this.$route.params.permlink),
          replyFlag: false,
          text:'',
          areply:{},
          tags: 'tags(标签):　'
        }
      },

      methods:{
        addCollection() {
          let db = this.$store.state.db
          const collection = this.$store.state.username + 'collection'
          // console.log(888, db, '版本号', db.version)

          //得到操作 'collection' 表的事务对象
          let transaction = db.transaction([collection], 'readwrite') //可读可写事务

          transaction.oncomplete = function (event) {
            console.log("事务操作完成")
          }
          transaction.onerror = function (event) {
            console.log("事务操作出错")
            console.dir(event)
          }
          transaction.onsuccess = function (event) {
            console.log('事务操作成功')
          }

          //从事务对象里得到表里的objectStore对象
          let objectStore = transaction.objectStore(collection)
          let post = this.post
          objectStore.add(post)
          // console.log(456, "收藏成功")

          //设置收藏标记
          let collectionFlag = this.$route.params.permlink
          localStorage.setItem(collectionFlag, 'larkBlog')
          this.flag = localStorage.getItem(this.$route.params.permlink)
        },

        reply(){
          this.replyFlag = true
        },

        replypost(){
          this.replyFlag = false
          let wif = this.$store.state.password
          let parentAuthor = this.$route.params.author
          let parentPermlink = this.$route.params.permlink
          let author = this.$store.state.username
          let permlink = this.getstr()
          let title = ''
          let body = this.text
          let tags = ['cn', 'larkBlog']
          let jsonMetadata = {"tags": tags, "dapp": "larkBlog", "format": "markdown"}

          //发布到hive上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

          //把数据拼成一个JSON直接显示在前端
          let reply = {
            "parent_author": parentAuthor,
            "parent_permlink": parentPermlink,
            "title": title,
            "author": author,
            "permlink": permlink,
            "body": body,
            "created": new Date().toISOString().split('.')[0],
            "json_metadata": JSON.stringify(jsonMetadata)
          }
          this.areply = reply
          this.text = ''
        },

        clear(){
          this.replyFlag = false
          this.text = ''
        },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }

      },

      watch:{
          flag(newVal){
            return newVal
          }
      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        },

      },

      mounted() {
        //如果vuex中有值，则直接取出，否则从hive网络中取
        if(this.$store.state.feed != false){
          let posts = this.$store.state.feed
          posts.forEach(item => {
            if(item.permlink == this.$route.params.permlink){
              this.post = item
              // console.log(622, '找到啦')
            }
          })
        }else{
          let _this= this
          this.hive.api.getContent(this.$route.params.author, this.$route.params.permlink, function(err, result) {
            _this.post = result
          })
        }

        //如果数据库没打开，则打开
        if(this.$store.state.posts == false){
          let _this = this
          // console.log(433, 'db is null')
          async function main() {
            let db = await _this.initDatabase()
            _this.$store.commit('saveDB',db)
            let posts = await _this.getAllPosts(db)
            _this.$store.commit('savePosts', posts.reverse())
          }
          main()
        }



      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      components: {
        OtherReply
      }
    }
</script>

<style scoped>
  h1{
    font-size: 2rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }

  .posttime{
    text-align: center;
  }

  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 3rem;
   }

  #main{
    width: 90%;
    height: 20rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }


  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }


</style>
