<template>
  <div class="article">
    <b-input
      id="inline-form-input-name"
      class="mb-2 mr-sm-2 mb-sm-0 search"
      placeholder="搜索" v-model="keywords"
    ></b-input>
    <div class="content" v-for="post in search(keywords)">

      <router-link :to="'/trend/@'+post.author+'/'+post.permlink">
        <b-img :src="post.body | takeImage" fluid  class="courseimg"></b-img>
      </router-link>

      <div class="title">
        <router-link :to="'/trend/'+'@'+post.author+'/'+post.permlink">
          <span style="font-size: 1.2rem">{{ post.title }}</span><br>
        </router-link>
        <p class="posttime">@{{ post.author }} 　{{ post.created  | formatTime}}</p>
      </div>
      <p style="padding: 0 1rem 1rem 1rem">{{ post.body | abstractFn }} ......</p>

      <!--<div style="clear:both;"></div>-->


    </div>

    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>

    <div class="btn">
      <b-button variant="outline-primary" @click="getNext">查看更多</b-button>
    </div>
  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
        name: "Index",
      data(){
          return{
            keywords:'',
            posts:[],
            author:'',
            permlink:'',
            isLoading: true
          }
      },
      methods:{
        search(keywords){
          let posts = this.posts
          return posts.filter(item => {
            if(item.title.includes(keywords)){
              // console.log(166, '查到了！')
              return item
            }
          })
        },
        getHot(){
            let that = this
            let query = { tag: "hive-105017", limit : 20 }
            this.hive.api.getDiscussionsByTrending(query, function(err, data) {
              that.posts = data
              //取到最后一篇文章的author和Permlink，做为下一次查询的起始点
              that.author = data[data.length - 1].author
              that.permlink = data[data.length - 1].permlink
              //将得到的数据存入vuex中
              that.$store.commit('saveHot', data)
              that.isLoading = false
            })
          },

        getNext(){
          let that = this
          that.isLoading = true
          let query = {tag: "hive-105017", limit: 20, start_author: this.author, start_permlink: this.permlink}
          this.hive.api.getDiscussionsByTrending(query, function(err, data) {
            data.forEach(post => {
              if (post.permlink != that.permlink) {
                that.posts.push(post)
              }
            })
            //取到最后一篇文章的author和Permlink，做为下一次查询的起始点
            that.author = data[data.length - 1].author
            that.permlink = data[data.length - 1].permlink
            //将得到的数据存入vuex中
            that.$store.commit('saveHot', that.posts)
            that.isLoading = false
            if(data.length == 1){
              alert('没有更多了！')
            }
          })
        }
      },

      components: {
        Loading
      },

      mounted() {
        //如果vuex中有值，则直接取出，否则从hive网络中取
        if(this.$store.state.hot != false){
          this.posts = this.$store.state.hot
          this.isLoading = false

          //把初始化的数值补回
          let data = this.$store.state.hot
          this.author = data[data.length - 1].author
          this.permlink = data[data.length - 1].permlink
        }else{
          this.getHot()
        }
      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        },
        takeImage(text){
          let reg = /!\[(.*?)\]\((.*?)\)/
          // let reg = /\!\[.*?\)/
          let a = text.match(reg)
          if(a === null){
            return
          }
          return a[2]
        },
        abstractFn(res){
          if(!res){
            return ''
          }else{
            let str=res.replace(/(\*\*|__)(.*?)(\*\*|__)/g,'')          //全局匹配内粗体
                    .replace(/\!\[[\s\S]*?\]\([\s\S]*?\)/g,'')           //全局匹配图片
                    .replace(/\([\s\S]*?\)/g,'')                              //全局匹配连接
                    .replace(/<\/?.+?\/?>/g,'')                             //全局匹配内html标签
                    .replace(/http.*\w/g,'')                              //全局http连接
                    .replace(/(\*)(.*?)(\*)/g,'')                               //全局匹配内联代码块
                    .replace(/`{1,2}[^`](.*?)`{1,2}/g,'')                       //全局匹配内联代码块
                    .replace(/```([\s\S]*?)```[\s]*/g,'')                       //全局匹配代码块
                    .replace(/\~\~(.*?)\~\~/g,'')                               //全局匹配删除线
                    .replace(/[\s]*[-\*\+]+(.*)/g,'')                           //全局匹配无序列表
                    .replace(/[\s]*[0-9]+\.(.*)/g,'')                           //全局匹配有序列表
                    .replace(/(#+)(.*)/g,'')                                    //全局匹配标题
                    .replace(/(>+)(.*)/g,'')                                    //全局匹配摘要
                    .replace(/\r\n/g,"")                                        //全局匹配换行
                    .replace(/\n/g,"")                                          //全局匹配换行
            // .replace(/\s/g,"")                                          //全局匹配空字符;
            return str.slice(0,180)
          }
        }

      },
    }
</script>

<style scoped>

  .article{
    margin-top: 3rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 3rem auto;
    padding-bottom: 0.5rem;
    width: 50%;
    border: 1px solid #ddd;
    box-shadow: 5px 5px 3px #ebebeb;
    /*background-color: rgba(58,46,52,0.04);*/
  }
  .title{
    text-align: center;
    width: 95%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .courseimg{
    display: block;
    margin: 0 auto;
  }
  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;
  }

  .btn{
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
    border: none;
  }

  .search{
    margin: 0.5rem auto !important;
    width: 50%;
  }
</style>
