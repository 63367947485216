<template>
  <div id="app">
    <b-navbar toggleable="lg" type="white" id="navmanu"> 
      <b-navbar-brand @click="backtoindex">
        <img src="../static/images/logo.svg" class="d-inline-block align-top" alt="lark blog">
      </b-navbar-brand>

       <!-- 切换steem节点-->
      <b-dropdown text="节点" variant="transparent"  class="m-2 mode">
        <b-dropdown-item-button @click="setAnyx" :class="nodeIndex === '1' ? 'nodeActive': 'nodeNoActive'">
          https://anyx.io
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button @click="setPrivex" :class="nodeIndex === '2' ? 'nodeActive': 'nodeNoActive'">
          https://hived.privex.io
        </b-dropdown-item-button>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button @click="setHiveblog" :class="nodeIndex === '3' ? 'nodeActive': 'nodeNoActive'">
          https://api.hive.blog
        </b-dropdown-item-button>
      </b-dropdown>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <router-link to="/">首页</router-link>
          <router-link to="/feeds">关注</router-link>
          <router-link to="/searchs">查询作者</router-link>
          <router-link to="/blogs">文章列表</router-link>
          <!-- <router-link to="/category">专栏</router-link> -->
          <router-link to="/collections">收藏</router-link>
          <router-link to="/comments">评论</router-link>
          <router-link to="/reply">回复</router-link>
          <router-link to="/post">发布</router-link>
          <!--<router-link to="/createcommunity">创建社区</router-link>-->

          <div v-if="$store.state.username">
            <a href="#">@{{ $store.state.username }}</a>
            <a href="#" @click="logout">注销</a>
          </div>
          <div v-else >
            <router-link to="/register">注册</router-link>
            <!--<router-link to="/Registerhive">hive注册</router-link>-->
            <router-link to="/login">登录</router-link>
          </div>

         
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view/>


  </div>
</template>

<script>
  import Category from './components/Category'
  import Reply from './components/Reply'
  export default {
    name: 'App',
    data(){
      return {
        username:'',
        password:'',
        keywords: '',
        parmsg: '这是父数据',
        nodeIndex: localStorage.getItem("nodeIndex")

      }
    },
    methods: {
      backtoindex(){
        this.$router.push({path: '/'})
      },

      logout(){
        this.$store.commit('clearUser')
      },
      setAnyx(){
        let hiveNode = 'https://anyx.io'
        localStorage.setItem("hiveNode", hiveNode)
        localStorage.setItem("nodeIndex", "1")
        this.$router.go(0)
      },
      setPrivex(){
        let hiveNode = 'https://hived.privex.io'
        localStorage.setItem("hiveNode", hiveNode)
        localStorage.setItem("nodeIndex", "2")
        this.$router.go(0)
      },     
      setHiveblog(){
        let hiveNode = 'https://api.hive.blog'
        localStorage.setItem("hiveNode", hiveNode)
        localStorage.setItem("nodeIndex", "3")
        this.$router.go(0)
      },

    },

    filters:{
      formatTime(data){
        return data.substring(0,10)
      }
    },

    components: {
      Category,
      Reply
    },

    mounted() {
      if(localStorage.getItem("nodeIndex") == null){
        localStorage.setItem("nodeIndex", "1")
      }
    }

  }
</script>

<style>
  html,body{
    height: 100%;
  }
  #app{
    position:relative;
    width:100%;
    min-height:100%;
    padding-bottom: 1rem;
    box-sizing: border-box;
  }

  .router-link-exact-active{
    /* color: darkcyan !important; */
    border-bottom: 1px solid darkcyan;
    text-align: center;
  }


  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  #navmanu {
    /* background-color: #da741b !important; */

  }
  .align-top{
    margin-left: 3rem;
  }

  #nav-collapse{
    margin-right: 4rem;
  }
  #nav-collapse a{
    color: dimgray;
    margin-right: 0.4rem;
    margin-left: 0.4rem;
		border-radius: 0.4rem;
  }
  #nav-collapse a:hover{
    color: darkcyan;
    text-decoration: none;
  }
  .mode{
    z-index: 5000;
    /* background: red; */
  }

  .nodeActive{
    /*color:red;*/
    /*border-bottom: 1px solid red;*/
    font-size: 1.1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid red;
    border-radius: 1rem;
  }
  .nodeNoActive{

  }
</style>
