const postToHive= function(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata){
  //发布到hive上
  // return new Promise(resolve => {
  // this.hive.broadcast.comment(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata, function(err, result) {
  //   console.log(234567, err, result)
  //   if(err){
  //     alert("错误！未能同步到Hive区块链上！\n具体原因如下：\n" + err)
  //   }
  //   resolve()
  // })
  // })

  this.hive.broadcast.comment(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata, function (err, result) {
      console.log(123456, err, result)
      if (err) {
        alert("错误！未能同步到hive区块链上！\n具体原因如下：\n" + err)
      }
    })
}

export {postToHive}
