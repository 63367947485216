const getAllReplies = function (db) {
  let that = this
  const reply = this.$store.state.username + 'reply'
  let transaction = db.transaction([reply], 'readwrite')
  let objectStore = transaction.objectStore(reply)
  let allreplies = []

  return new Promise(resolve => {
    objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
      let cursor = event.target.result
      // console.log(cursor, '游标');
      if (cursor) {
        // console.log(111, cursor.key, cursor.value);
        allreplies.push(cursor.value)
        cursor.continue()
      } else {
        // console.log('没有更多数据了！')
        resolve(allreplies)
      }
    }

    //如果游标遍历出现错误
    objectStore.openCursor().onerror = function (event) {
      console.dir(event)
    }
  })

}

export {getAllReplies}
