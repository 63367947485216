<template>
  <div id="content">

    <router-link :to="'/@'+onereply.root_author+'/'+onereply.root_permlink">　
      <h1>原文：{{ onereply.root_title}} </h1>
    </router-link>

    父评论：
    <p>@{{ parentreply.author }}　{{ parentreply.created | formatTime }}</p>
    <mavon-editor
      class="replymd"
      :value="parentreply.body"
      :subfield = "prop.subfield"
      :defaultOpen = "prop.defaultOpen"
      :toolbarsFlag = "prop.toolbarsFlag"
      :editable="prop.editable"
      :boxShadow="false"
      :scrollStyle="prop.scrollStyle"
    ></mavon-editor>


    <div class="child">
    <p>@{{ onereply.author }}　{{ onereply.created | formatTime }}</p>
    <mavon-editor
      class="replymd"
     :value="onereply.body"
    :subfield = "prop.subfield"
    :defaultOpen = "prop.defaultOpen"
    :toolbarsFlag = "prop.toolbarsFlag"
    :editable="prop.editable"
     :boxShadow="false"
    :scrollStyle="prop.scrollStyle"
    ></mavon-editor>
        <b-button variant="outline-primary" @click="edit()">编辑</b-button>
        <div v-if="editFlag">
          <div id="main2">
            <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
          </div>
          <b-button variant="outline-primary" @click="refresh(onereply)">更新</b-button>
          <b-button variant="outline-primary" @click="clear">取消</b-button>
        </div>

    </div>


  </div>
</template>

<script>

  import OtherReply from './OtherReply'
    export default {
      name: "CommentDetail",
      data() {
        return {
          db:{},
          data:'',
          flag:localStorage.getItem(this.$route.params.permlink),
          replyFlag: false,
          editFlag: false,
          text:'',
          onereply:{},
          parentreply:{}
        }
      },

      methods:{
        edit(){
          this.editFlag = true
          this.text = this.onereply.body
        },

        refresh(onereply){
          this.editFlag = false
          this.$set(onereply, "body", this.text)

          let wif = this.$store.state.password
          let author = this.$store.state.username
          let parentAuthor = onereply.parent_author
          let  parentPermlink = onereply.parent_permlink
          let permlink = onereply.permlink
          let title = onereply.title
          let body = this.text
          let jsonMetadata = onereply.json_metadata

          //发布到hive上
          this.postToSteem(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

        },

        clear(){
          this.editFlag = false
          this.text = ''
        },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }

      },

      watch:{
          flag(newVal){
            return newVal
          }
      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        },

      },

      mounted() {
        let _this = this
        this.hive.api.getContent(this.$route.params.parent_author, this.$route.params.parent_permlink, function(err, result) {
          // console.log(234, err, result)
          _this.parentreply = result
        })
        this.hive.api.getContent(this.$route.params.author, this.$route.params.permlink, function(err, result) {
          // console.log(356, err, result)
          _this.onereply = result
        })


      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      components: {
        OtherReply
      }
    }
</script>

<style scoped>
  h1{
    font-size: 1.5rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
  a{
    color: rgba(55,53,58,0.75);
  }
  a:hover{
    color: darkcyan;
    text-decoration: none;
  }
  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 3rem;
   }
  .replymd{
    min-height: auto;
    box-shadow: transparent;
    margin-bottom: 0.5rem;
  }

  #main{
    width: 95%;
    height: 20rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .child{
    margin-left: 2rem;
  }

  .secchild{
    margin-left: 4rem;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: transparent;
    font-size: 0.7rem;

  }
  .btn-outline-primary:hover{
    background-color: transparent;
    color: darkcyan;
  }


</style>
