<template>
    <div class="reply">
      <h5>评论</h5>
        <div v-for="item in replies">
            <OtherReplylist :parentmsg="item"></OtherReplylist>
        </div>
    </div>
</template>

<script>
  import OtherReplylist from './OtherReplylist'
    export default {
      name: "OtherReply",
      data(){
        return {
          replies:[],
        }
      },
      methods:{
        delreplies(){
          let that = this
          let keys = that.keys
          let db = that.$store.state.db
          const reply = that.$store.state.username + 'reply'
          let transaction = db.transaction([reply], 'readwrite')
          let objectStore = transaction.objectStore(reply)
          return new Promise(resolve => {
            keys.forEach(item => {
              objectStore.delete(item)
              // console.log(444,'del suc')
            })
            resolve()
          })

        },

      },

      watch:{
        otherreply(){
          this.replies.push(this.otherreply)
          // console.log(123, this.otherreply)
        }
      },

      mounted() {
        let _this = this
        let author = this.$route.params.author
        let permlink = this.$route.params.permlink

        async function getReplies(author, permlink, res=[]) {
          let replies = await _this.hive.api.getContentRepliesAsync(author, permlink)
          let children = []
          replies.forEach(item => {
            res.push(item)
            if(item.children > 0){
              //把得到的子数据塞进 .child 中
              children.push(getReplies(item.author, item.permlink, item.child=[]))
            }
          })
          await Promise.all(children)
          return res
        }
        getReplies(author, permlink)
          .then(res => {
            // console.log(777, res)
            _this.replies= res
          })

      },

      props: ['otherreply'],

      components: {
        OtherReplylist
      }

    }
</script>

<style scoped>
  .reply{
    color: rgba(55,53,58,0.75);
    width: 95%;
    display: block;
    margin-top: 2rem;
  }

</style>
