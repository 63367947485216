<template>
  <div>
      <div class="article">
        <div class="content" v-for="reply in repliesreverse.slice(0, 30)">
          <p class="posttime">@{{ reply[1].op[1].author }}　{{ reply[1].timestamp | formatTime }}</p><br>
          <router-link :to="'/replydetail/'+'@'+reply[1].op[1].parent_author+'/'+reply[1].op[1].parent_permlink+'/'+'@'+reply[1].op[1].author+'/'+reply[1].op[1].permlink">

          <mavon-editor
            id="replymd"
            :value="reply[1].op[1].body"
            :subfield = "prop.subfield"
            :defaultOpen = "prop.defaultOpen"
            :toolbarsFlag = "prop.toolbarsFlag"
            :editable="prop.editable"
            :boxShadow="false"
            :scrollStyle="prop.scrollStyle"
          ></mavon-editor>
          </router-link>

         </div>
        <!--隐藏部分-->
        <div class="content" v-if="replyFlag" v-for="reply in repliesreverse.slice(30)">
          <p class="posttime">@{{ reply[1].op[1].author }}　{{ reply[1].timestamp | formatTime }}</p><br>
          <router-link :to="'/replydetail/'+'@'+reply[1].op[1].parent_author+'/'+reply[1].op[1].parent_permlink+'/'+'@'+reply[1].op[1].author+'/'+reply[1].op[1].permlink">

            <mavon-editor
              id="replymd2"
              :value="reply[1].op[1].body"
              :subfield = "prop.subfield"
              :defaultOpen = "prop.defaultOpen"
              :toolbarsFlag = "prop.toolbarsFlag"
              :editable="prop.editable"
              :boxShadow="false"
              :scrollStyle="prop.scrollStyle"
            ></mavon-editor>
          </router-link>

        </div>

       <div class="btn" v-if="nextFlag">
         <b-button variant="outline-primary" @click="getAll">查看全部</b-button>
       </div>
     </div>

    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>

  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
      name: "ReplyToAuthor",
      data() {
        return {
          replies:[],
          replyFlag: false,
          editFlag: false,
          nextFlag: true,
          isLoading: false
          }
      },
      methods: {
        getAll(){
          this.replyFlag = true
          this.nextFlag = false
        },

        saveReplies(db, replies) {
          const replyToAuthor = this.$store.state.username + 'replytoauthor'
          // console.log(888, db, '版本号', db.version)
          //得到操作表的事务对象
          let transaction = db.transaction([replyToAuthor], 'readwrite') //可读可写事务
          return new Promise(resolve => {
            transaction.oncomplete = function (event) {
              console.log("123, 事务操作完成")
              resolve()
            }
            transaction.onerror = function (event) {
              console.log("事务操作出错")
              console.dir(event)
            }
            transaction.onsuccess = function (event) {
              console.log('222, 事务操作成功')
            }
            //从事务对象里得到表里的objectStore对象
            let objectStore = transaction.objectStore(replyToAuthor)
            for (let i = replies.length-1; i >-1 ; i--) {
              objectStore.add(replies[i])
              console.log(256, "添加成功")
            }
            //设置初始化标记
            let replyToAuthorFlag = this.$store.state.username + 'replytoauthor'
            localStorage.setItem(replyToAuthorFlag, 'larkBlog')
          })
        }
      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      computed: {
        repliesreverse: function () {
          return this.$store.state.repliestoauthor
        },
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      components: {
        Loading
      },

      mounted() {
        let _this = this
        let author = this.$store.state.username
        let replyToAuthorFlag = this.$store.state.username + 'replytoauthor'

        async function getHiveReplies(author, limit){
          //获取对作者的最新评论
          let replies = []
          let res = await _this.hive.api.getAccountHistoryAsync(author, -1, limit)
          res.forEach(item => {
            if(item[1].op[0] == "comment" & item[1].op[1].parent_author == author){
              replies.push(item)
            }
          })
          console.log(566, replies)
          return replies
        }


        async function main(){
          if(_this.$store.state.username){
            //如果已登录
            if(localStorage.getItem(replyToAuthorFlag) == null){
              _this.isLoading = true
              //如果是第一次进入
              //从hive上获取新评论
              let replies = await getHiveReplies(author, 800)
              _this.$store.commit('saveRepliesToAuthor', replies.reverse())
              //存入数据库
              let db = _this.$store.state.db
							// console.log(888, db)
              await _this.saveReplies(db, replies)
              _this.isLoading = false
              console.log("评论存入数据库成功！")
            }else{
              //如果是后续进入页面
              if(_this.$store.state.posts == false){
                console.log("345，刷新或数据库没开")
                let db = await _this.initDatabase()
                _this.$store.commit('saveDB',db)
                let posts = await _this.getAllPosts(db)
                _this.$store.commit('savePosts', posts.reverse())

                let replies = await _this.getRepliesToAuthor(db)
                _this.$store.commit('saveRepliesToAuthor', replies.reverse())

                //同步评论
                //取得最新回复的区块标记
                let latestReplyFlag = replies[0][1].block

                //从hive上获取新评论
                let newres = await getHiveReplies(author, 500)
                // console.log(588, newres)
                let newreplies = []
                newres.forEach(item => {
                  if(latestReplyFlag < item[1].block){
                    console.log(22, '有新评论')
                    newreplies.push(item)
                  }
                })
                if(newreplies.length > 0){
                  //存入数据库
                  await _this.saveReplies(db, newreplies.reverse())
                  console.log(566, '添加新评论成功！')
                  //重新读取数据并显示
                  let replies2 = await _this.getRepliesToAuthor(db)
                  _this.$store.commit('saveRepliesToAuthor', replies2.reverse())
                }


              }else{
                if(_this.$store.state.repliestoauthor == false){
                  console.log("444，获得数据库并读取评论")
                  let db =_this.$store.state.db
                  let replies = await _this.getRepliesToAuthor(db)
                  _this.$store.commit('saveRepliesToAuthor', replies.reverse())

                  //同步评论
                  //取得最新回复的区块标记
									// console.log(666, replies)
                  let latestReplyFlag = replies[0][1].block

                  //从hive上获取新评论
                  let newres = await getHiveReplies(author, 500)
                  // console.log(599, newres)
                  let newreplies = []
                  newres.forEach(item => {
                    if(latestReplyFlag < item[1].block){
                      console.log(33, '有新评论')
                      newreplies.push(item)
                    }
                  })
                  if(newreplies.length > 0){
                    //存入数据库
                    await _this.saveReplies(db, newreplies.reverse())
                    console.log(577, '添加新评论成功！')
                    //重新读取数据并显示
                    let replies2 = await _this.getRepliesToAuthor(db)
                    _this.$store.commit('saveRepliesToAuthor', replies2.reverse())
                  }
                }
              }
            }
          }
        }
        main()
      }

    }

</script>

<style scoped>

  .article{
    margin-top: 2rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 0.5rem auto;
    padding-bottom: 0.5rem;
    width: 70%;
  }



  #replymd{
    min-height: auto;

  }
  #replymd a{
    color: blue !important;
  }
  a:hover{
    color: darkcyan;
  }

  #replymd2{
    min-height: auto;
  }
  .btn{
    display: block;
    margin-left: 10%;
    margin-bottom: 1rem;
  }
  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }

</style>
