<template>
  <div id="content">
    <h1>{{ post.title }}</h1>
    <p class="posttime">@{{ post.author }} 　{{ post.created | formatTime }}</p>

    <mavon-editor
      class="md"
      :value="post.body + '<br>' + '<br>' + tags + JSON.parse(post.json_metadata).tags.join('　')"
    :subfield = "prop.subfield"
    :defaultOpen = "prop.defaultOpen"
    :toolbarsFlag = "prop.toolbarsFlag"
    :editable="prop.editable"
    :scrollStyle="prop.scrollStyle"
    ></mavon-editor>

    <br>

    <div v-if="this.$store.state.username">
      <div v-if="this.$store.state.username === post.author">
        <b-button variant="outline-primary" @click="edit(post.permlink)">编辑</b-button>
        <b-button variant="outline-primary"><a target="_blank" :href="'https://hived.com/@'+this.$store.state.username+'/'+post.permlink">文章数据</a></b-button>
        <br><br>
        <Reply></Reply>
      </div>
      <div v-else>
          <b-button variant="outline-primary" @click="reply">回复</b-button>
          <span v-if="flag">
           已收藏
         </span>
          <span v-else >
         <b-button variant="outline-primary" @click="addCollection">收藏</b-button>
         </span>
        <b-button variant="outline-primary"><a target="_blank" :href="'https://hived.com/@'+post.author+'/'+post.permlink">文章数据</a></b-button>
        <div v-if="replyFlag">
          <div id="main">
            <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
          </div>

          <b-button variant="outline-primary" @click="replypost">提交</b-button>
          <b-button variant="outline-primary" @click="clear">取消</b-button>
        </div>
        <br>
        <OtherReply :otherreply="areply"></OtherReply>
      </div>



    </div>


  </div>
</template>

<script>
  import Reply from './Reply'
  import OtherReply from './OtherReply'
    export default {
      name: "Article",
      data() {
        return {
          post: {},
          db:{},
          key:'',
          flag:localStorage.getItem(this.$route.params.permlink),
          areply:{},
          replies:[],
          replyFlag: false,
          tags: 'tags(标签):　',
          text:'',
        }
      },

      methods:{
        addCollection() {
          let db = this.$store.state.db
          const collection = this.$store.state.username + 'collection'
          // console.log(888, db, '版本号', db.version)

          //得到操作 'collection' 表的事务对象
          let transaction = db.transaction([collection], 'readwrite') //可读可写事务

          transaction.oncomplete = function (event) {
            console.log("事务操作完成")
          }
          transaction.onerror = function (event) {
            console.log("事务操作出错")
            console.dir(event)
          }
          transaction.onsuccess = function (event) {
            console.log('事务操作成功')
          }

          //从事务对象里得到表里的objectStore对象
          let objectStore = transaction.objectStore(collection)
          let post = this.post
          objectStore.add(post)
          // console.log(456, "收藏成功")

          //设置收藏标记
          let collectionFlag = this.$route.params.permlink
          localStorage.setItem(collectionFlag, 'hivejiang')
          this.flag = localStorage.getItem(this.$route.params.permlink)
        },
        edit(permlink){
          this.$router.push({path: '/edit/'+permlink})
        },
        reply(){
          this.replyFlag = true
        },
        replypost(){
          this.replyFlag = false
          let wif = this.$store.state.password
          let parentAuthor = this.$route.params.author
          let parentPermlink = this.$route.params.permlink
          let author = this.$store.state.username
          let permlink = this.getstr()
          let title = ''
          let body = this.text
          let tags = ['cn', 'hivejiang']
          let jsonMetadata = {"tags": tags, "dapp": "hivejiang", "format": "markdown"}

          //发布到hive上
          this.postToSteem(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

          //把数据拼成一个JSON直接显示在前端
          let reply = {
            "parent_author": parentAuthor,
            "parent_permlink": parentPermlink,
            "title": title,
            "author": author,
            "permlink": permlink,
            "body": body,
            "created": new Date().toISOString().split('.')[0],
            "json_metadata": JSON.stringify(jsonMetadata)
          }
          this.areply = reply
          this.text = ''
        },

        clear(){
          this.replyFlag = false
          this.text = ''
        },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }
      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      components: {
        Reply,
        OtherReply
      },
      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      mounted() {
        let _this = this
        let author = this.$route.params.author
        let permlink = this.$route.params.permlink

        async function main() {
          if(_this.$store.state.username) {
            if (_this.$store.state.posts != false) {
              //直接在hive中查找数据
              _this.hive.api.getContent(author, permlink, function(err, result) {
                _this.post = result
              })

            } else {
              //刷新则先打开数据库并获得数据
              let db = await _this.initDatabase()
              _this.$store.commit('saveDB', db)
              let posts = await _this.getAllPosts(db)
              _this.$store.commit('savePosts', posts.reverse())
              //直接在hive中查找数据
              _this.hive.api.getContent(author, permlink, function(err, result) {
                _this.post = result
              })
            }
          }
        }
        main()

      },

    }
</script>

<style scoped>
  h1{
    font-size: 2rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 0.2rem;
   }
  .posttime{
    text-align: center;
  }

  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;

  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }
</style>
