const saveOneToDb = function(post, db, table){
  return new Promise(resolve => {
    //得到操作 'testTable' 表的事务对象
    let transaction = db.transaction([table], 'readwrite') //可读可写事务
    transaction.oncomplete = function (event) {
      console.log("536, 事务操作完成")
      resolve()
    }
    transaction.onerror = function (event) {
      console.log("事务操作出错")
      console.dir(event)
    }
    transaction.onsuccess = function (event) {
      console.log('事务操作成功')
    }

    //从事务对象里得到表里的objectStore对象
    let objectStore = transaction.objectStore(table)
    objectStore.add(post)
  })
}

export {saveOneToDb}
