<template>
  <div>

      <b-input
        id="inline-form-input-name"
        class="mb-2 mr-sm-2 mb-sm-0 search"
        placeholder="搜索" v-model="keywords"
      ></b-input>

     <div class="article">
        <div class="content" v-for="post in search(keywords)">
          <router-link :to="'/collection/@'+post.author+'/'+post.permlink">{{ post.title }}　
            <p class="posttime">@{{ post.author }} 　{{ post.created  | formatTime}}</p>
          </router-link>

         </div>
     </div>

  </div>
</template>

<script>
    export default {
      name: "Collections",
      data() {
        return {
          keywords: '',
          }
      },
      methods: {
        search(keywords){
          let posts = this.$store.state.collections
          return posts.filter(item => {
            if(item.title.includes(keywords)){
              // console.log(166, '查到了！')
              return item
            }
          })
        },

      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      mounted() {
        let _this = this
        if(this.$store.state.posts == false){
          //如果数据库没打开，则打开
          console.log(455, 'db is null')
          async function main() {
            let db = await _this.initDatabase()
            _this.$store.commit('saveDB',db)
            let posts = await _this.getAllPosts(db)
            _this.$store.commit('savePosts', posts.reverse())
            let collections = await _this.getCollections(db)
            _this.$store.commit('saveCollections', collections.reverse())

          }
          main()
        }else{
          //直接读取数据库
          async function main() {
            let db = _this.$store.state.db
            let collections = await _this.getCollections(db)
            _this.$store.commit('saveCollections', collections.reverse())
          }
          main()
        }


      },

 }

</script>

<style scoped>

  .article{
    margin-top: 3rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    height: 3rem;
    width: 70%;
    border-bottom: 1px solid #ddd;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;
  }

  .search{
    margin: 1rem auto !important;
    width: 75%;
  }
</style>
