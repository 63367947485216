import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'vue-cookies'

Vue.use(Vuex)

export default new Vuex.Store({

  // 组件中通过 this.$store.state.username 调用
  state: {
    username: Cookie.get("username"),
    password: Cookie.get("password"),
    steempassword: Cookie.get("steempassword"),
    posts:[],
    db:{},
    feed:[],
    hot:[],
    collections:[],
    searchPosts:[],
    repliestoauthor:[]

  },
  mutations: {
    // 组件中通过 this.$store.commit('saveToken',参数)  调用,只能带一个参数，有多个参数以对象的形式传入
    saveUser: function (state, userobj) {
      state.username = userobj.username
      state.password = userobj.password 
      state.steempassword = userobj.steempassword

      Cookie.set("username", userobj.username, "30d")
      Cookie.set("password", userobj.password, "30d") 
      Cookie.set("steempassword", userobj.steempassword, "30d")

    },
    savePosts: function (state,  posts) {
      state.posts = posts
    },

    saveRepliesToAuthor: function (state,  repliestoauthor) {
      state.repliestoauthor = repliestoauthor
    },

    saveFeed: function (state,  feed) {
      state.feed = feed
    },

    saveHot: function (state,  hot) {
      state.hot= hot
    },

    saveCollections: function (state,  collections) {
      state.collections= collections
    },

    saveSearchPosts: function (state,  searchPosts) {
      state.searchPosts= searchPosts
    },

    saveDB: function (state, db){
      state.db = db
    },


    clearUser: function (state) {
      state.username = null
      state.password = null  
      state.steempassword = null  
      Cookie.remove('username')
      Cookie.remove('password')
      Cookie.remove('steempassword')
    }
  }
})

