const getRepliesToAuthor = function(db){
  // console.log(656, that.db)
  const replyToAuthor = this.$store.state.username + 'replytoauthor'
  let transaction = db.transaction([replyToAuthor ], 'readwrite')
  let objectStore = transaction.objectStore(replyToAuthor )
  let repliestoauthor= []

  return new Promise(resolve => {
    objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
      let cursor = event.target.result
      // console.log(cursor, '游标');
      if (cursor) {
        // console.log(111, cursor.key, cursor.value);
        repliestoauthor.push(cursor.value)
        cursor.continue()
      } else {
        // console.log('333, 没有更多数据了！')
        resolve(repliestoauthor)
      }
    }

    //如果游标遍历出现错误
    objectStore.openCursor().onerror = function (event) {
      console.dir(event)
    }
  })

}

export {getRepliesToAuthor}
