const postToSteem = function(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata){
  //发布到steem上
    this.steem.broadcast.comment(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata, function (err, result) {
      console.log(123456, err, result)
      if (err) {
        alert("错误！未能同步到Steem区块链上！\n具体原因如下：\n" + err)
      }
    })
}

export {postToSteem}
