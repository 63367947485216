<template>
  <div id="content">
    <h1>{{ post.title }}</h1>
    <p class="posttime">@{{ post.author }} 　{{ post.created | formatTime }}</p>

    <mavon-editor
      class="md"
      :value="post.body"
    :subfield = "prop.subfield"
    :defaultOpen = "prop.defaultOpen"
    :toolbarsFlag = "prop.toolbarsFlag"
    :editable="prop.editable"
    :scrollStyle="prop.scrollStyle"
    ></mavon-editor>


    <div v-if="$store.state.username">
      <b-button variant="outline-primary" @click="del" class="del">取消收藏</b-button>
    </div>
    <br>



  </div>
</template>

<script>

    export default {
      name: "Collection",
      data() {
        return {
          post: {},
          key:'',
        }
      },

      methods:{
        searchKey() {
          let that = this
          let permlink = this.$route.params.permlink
          let db = this.$store.state.db
          const collection = this.$store.state.username + 'collection'
          let transaction = db.transaction([collection], 'readwrite')
          let objectStore = transaction.objectStore(collection)

          objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
            let cursor = event.target.result
            // console.log(cursor, '游标');
            if (cursor) {
              // console.log(11, cursor.key, cursor.value);
              if (cursor.value.permlink == permlink) {
                // console.log(666, '查到了666');
                that.key = cursor.key
              }
              cursor.continue()
            }

            //如果游标遍历出现错误
            objectStore.openCursor().onerror = function (event) {
              console.dir(event)
            }
          }
        },

        del(){
          let db = this.$store.state.db
          const collection = this.$store.state.username + 'collection'
          let transaction = db.transaction([collection], 'readwrite')
          let objectStore = transaction.objectStore(collection)
          objectStore.delete(this.key)

          //删除收藏标记
          let collectionFlag = this.$route.params.permlink
          localStorage.removeItem(collectionFlag)

          this.$router.push('/collection')

        },

        searchPosts(posts){
          let permlink = this.$route.params.permlink
          posts.forEach(item => {
            if(item.permlink == permlink){
              this.post = item
            }
          })
        },
      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      mounted() {
        let _this = this
        async function main() {
          if(_this.$store.state.collections != false){
            //直接查找数据
            let collections = _this.$store.state.collections
            _this.searchPosts(collections)
            _this.searchKey()
          }else{
            //刷新则先打开数据库并获得数据
            console.log(99,'fresh')
            let db = await _this.initDatabase()
            _this.$store.commit('saveDB',db)
            let posts = await _this.getAllPosts(db)
            _this.$store.commit('savePosts', posts.reverse())
            let collections = await _this.getCollections(db)
            _this.$store.commit('saveCollections', collections.reverse())
            _this.searchPosts(collections)
            _this.searchKey()
          }
        }
        main()

      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      }
    }
</script>

<style scoped>
  h1{
    font-size: 2rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 0.2rem;
   }
  .posttime{
    text-align: center;
  }

  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;

  }

  .del{
    margin-top: 2rem;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }
</style>
