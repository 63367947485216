const getAllPosts = function(db){
  // console.log(656, that.db)
  let tableName = this.$store.state.username
  let transaction = db.transaction([tableName], 'readwrite')
  let objectStore = transaction.objectStore(tableName)
  let posts = []

  return new Promise(resolve => {
    objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
      let cursor = event.target.result
      // console.log(cursor, '游标');
      if (cursor) {
        // console.log(111, cursor.key, cursor.value);
        posts.push(cursor.value)
        cursor.continue()
      } else {
        // console.log('没有更多数据了！')
        // console.log(123, that.posts)
        resolve(posts)
      }
    }

    //如果游标遍历出现错误
    objectStore.openCursor().onerror = function (event) {
      console.dir(event)
    }
  })

}

export {getAllPosts}
