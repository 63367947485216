<template>
  <div id="category">
    <h1>专栏</h1>

    <div id="titlename">
    <b-input
      id="inline-form-input-name"
      class="mb-2 mr-sm-2 mb-sm-0 cate"
      placeholder="请输入专栏名" v-model="name"
    ></b-input>
    <b-input
      id="inline-form-input-name"
      class="mb-2 mr-sm-2 mb-sm-0 cate"
      placeholder="请输入tag" v-model="tag"
    ></b-input></div>
    <div class="catrbtn">
    <b-button variant="outline-primary" @click="add">添加</b-button>
    <b-button variant="outline-primary" @click="save">保存</b-button>
      <em>(专栏名可以随意取，tag则必须为文章包含的tag!)</em>
    </div>

    <div v-for="item in category" class="zhuanlan">
      <p class="title">专栏: {{ item.name }} 　tag: {{ item.tag }} 　
        <b-button variant="outline-primary" @click="del(item.tag)">删除</b-button> </p>
        <div v-for="post in getCategory(item.tag)">
          <router-link :to="'/article/'+post.permlink"> <p class="posttime">{{ post.created | formatTime }}</p> 　{{ post.title }}</router-link>
        </div>
    </div>

  </div>
</template>

<script>
    export default {
        name: "Category",
      data(){
        return {
          newpost: [],
          name: '',
          tag: '',
          category: [
            {name: "轻量的博客", tag: "steemjiang"},
          ],
        }
      },

      methods: {
        add(){
          let category = { name: this.name, tag: this.tag}
          this.category.push(category)
          this.name= this.tag = ''
          // console.log(123, this.category)
        },
        save(){
          let cg = JSON.stringify(this.category)
          localStorage.setItem('category', cg)
          // console.log(234, localStorage.getItem("category"))
        },
        del(tag){
          // this.category.pop(id)
          this.category.splice(this.category.findIndex(item => item.tag === tag), 1)
        },
        getCategory(tag) {
          let posts = this.$store.state.posts
          return posts.filter(item => {
            let data_str = item.json_metadata
            let data_obj = JSON.parse(data_str)
            if(data_obj.tags.includes(tag)){
              // console.log(199, '查到了！')
              return item
            }
          })
        },

      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      mounted() {
          if(localStorage.getItem("category")==null){
            this.category = [
              {name: "轻量的博客", tag: "steemjiang"},
              {name: "比特币", tag: "bitcoin"},
            ]
          }else{
            this.category = JSON.parse(localStorage.getItem("category"))
          }

      }
    }
</script>

<style scoped>
  h1{
    font-size: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    padding-left: 2rem;
  }
 #inline-form-input-name{
   display: inline;
   width: 12rem;
 }

  #titlename{
    padding-left: 2rem;
  }
  .cate{
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    width: 10rem;

  }
  .catrbtn{
    margin-top: 1rem;
    padding-left: 2rem;
  }

  .zhuanlan{
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    align: center;
    border: 1px dashed #ddd;
   }

  .posttime{
    font-size: 0.8rem;
    display: inline;
  }

 #category{
  color: rgba(55,53,58,0.75);
  align: center;
  margin: 1rem auto;
  width: 70%;
  }
  .title{
    font-size: 1.2rem;
    margin-top: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ddd;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;

  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }

</style>
