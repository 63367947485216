<template>
  <div>

    <b-input-group prepend="作者名" class="mt-3" >
      <b-form-input v-model="author" @keyup.enter="searchauthor"  ></b-form-input>
      <b-input-group-append >
        <b-button variant="outline-success"  @click="searchauthor" >搜索</b-button>
      </b-input-group-append>
    </b-input-group>


      <b-input
        id="inline-form-input-name"
        class="mb-2 mr-sm-2 mb-sm-0 search"
        placeholder="文章关键字" v-model="keywords"
      ></b-input>


    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>

     <div class="article">
        <div class="content" v-for="post in search(keywords)">
              <router-link :to="'/search/@'+post.author+'/'+post.permlink"><p class="posttime">{{ post.created | formatTime }}</p> 　{{ post.title }}</router-link>
        </div>
     </div>

  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
      name: "Searchs",
      data() {
        return {
          keywords: '',
          author:'',
          isLoading: false
          }
      },
      methods: {
        searchauthor(){
          this.isLoading = true
          this.$store.state.searchPosts = []
          let author = this.author
          //查看用户历史以验证此用户是否存在
          this.hive.api.getAccountHistory(author, 0, 0, function(err, result) {
            if(result.length === 0){
              alert("用户不存在！")
            }
          })

          let _this = this
          async function main(){
            let searchposts= await _this.getHivePosts(author, null, 100, [])
            _this.$store.commit('saveSearchPosts', searchposts)
            _this.isLoading = false
          }
          main()
        },

        getHivePosts(author, startPermlink, limit, posts) {
          //传入要查询的作者名，初始标签，一次查询的条数，初始化数组
          let that = this
          return new Promise((resolve, reject) => {
            let beforeDate = new Date().toISOString().split('.')[0]
            that.hive.api.getDiscussionsByAuthorBeforeDate(author, startPermlink, beforeDate, limit, function (err, result) {
              if (result.length > 1) {
                let newResult = []
                result.forEach(post => {
                  if (post.permlink != startPermlink) {
                    newResult.push(post)
                  }
                })
                posts = [...posts, ...newResult]
                that.getHivePosts(author, result[result.length - 1].permlink, limit, posts)
                  .then(resolve)
                  .catch(reject)
              } else {
                resolve(posts)
              }
            })
          })
        },

        search(keywords){
          let posts = this.$store.state.searchPosts
          return posts.filter(item => {
            if(item.title.includes(keywords)){
              // console.log(166, '查到了！')
              return item
            }
          })
        },

      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      components: {
        Loading
      },

    }

</script>

<style scoped>

  .article{
    margin-top: 3rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    height: 3rem;
    width: 70%;
    border-bottom: 1px solid #ddd;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;
  }

  .mt-3{
    width: 75%;
    margin: 1rem auto !important;
  }
  .search{
    margin: 1rem auto !important;
    width: 75%;
  }



</style>
