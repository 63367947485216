<template>
    <div class="reply">
      <h5>评论</h5>
        <div v-for="item in replies">
            <Replylist :parentmsg="item"></Replylist>
        </div>
    </div>
</template>

<script>
  import Replylist from './Replylist'
    export default {
      name: "Reply",
      data(){
        return {
          firstreplies:[],
          replies:[],
          temp:[],
          keys:[],
          res:[]

        }
      },
      methods:{
        getRepliesFromDB(){
          let db = this.$store.state.db
          const reply = this.$store.state.username + 'reply'
          let transaction = db.transaction([reply], 'readwrite')
          let objectStore = transaction.objectStore(reply)
          let replies = []
          return new Promise(resolve => {
            objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
              let cursor = event.target.result
              // console.log(cursor, '游标');
              if (cursor) {
                // console.log(111, cursor.key, cursor.value);
                replies.push(cursor.value)
                cursor.continue()
              } else {
                // console.log('没有更多数据了！')
                resolve(replies)
              }
            }

            //如果游标遍历出现错误
            objectStore.openCursor().onerror = function (event) {
              console.dir(event)
            }
          })
        },

        filterRepliesDB(author, permlink, res=[]){
          let replies = this.firstreplies
          // console.log(755, replies)
          replies.forEach(item => {
            if(item.parent_author == author & item.parent_permlink == permlink){
              res.push(item)
              if(item.children > 0){
                //把得到的子数据塞进 .child 中
                this.filterRepliesDB(item.author, item.permlink, item.child=[])
              }
            }
          })
          this.replies = res
        },

        filterRepliesTemp(author, permlink, res=[]){
          let that = this
          let replies = that.res
          // console.log(755, replies)
          replies.forEach(item => {
            if(item.parent_author == author & item.parent_permlink == permlink){
              res.push(item)
              if(item.children > 0){
                //把得到的子数据塞进 .child 中
                that.filterRepliesTemp(item.author, item.permlink, item.child=[])
              }
            }
          })
          that.temp = res
          // console.log(688, 'temp')
        },

        searchRepliyKeys(allReplies) {
          let that = this
          let db = this.$store.state.db
          const reply = this.$store.state.username + 'reply'
          let transaction = db.transaction([reply], 'readwrite')
          let objectStore = transaction.objectStore(reply)

          return new Promise(resolve => {
            objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
              let cursor = event.target.result
              // console.log(cursor, '游标');
              if (cursor) {
                // console.log(11, cursor.key, cursor.value);
                allReplies.forEach(item => {
                  if (cursor.value.permlink == item.permlink) {
                    // console.log(666, '查到了666')
                    that.keys.push(cursor.key)
                  }
                })
                cursor.continue()
              }else {
                // console.log('没有更多数据了！')
                resolve()
              }
              //如果游标遍历出现错误
              objectStore.openCursor().onerror = function (event) {
                console.dir(event)
              }
            }
          })
        },

        delreplies(){
          let keys = this.keys
          let db = this.$store.state.db
          const reply = this.$store.state.username + 'reply'
          let transaction = db.transaction([reply], 'readwrite')
          let objectStore = transaction.objectStore(reply)
          return new Promise(resolve => {
            keys.forEach(item => {
              objectStore.delete(item)
              // console.log(444,'del suc')
            })
            resolve()
          })

        },

        saveReplies() {
          let db = this.$store.state.db
          const reply = this.$store.state.username + 'reply'

          //得到操作 'reply' 表的事务对象
          let transaction = db.transaction([reply], 'readwrite') //可读可写事务

          transaction.oncomplete = function (event) {
            console.log("事务操作完成")
          }
          transaction.onerror = function (event) {
            console.log(311,"事务操作出错")
            console.dir(event)
          }
          transaction.onsuccess = function (event) {
            console.log('事务操作成功')
          }

          //从事务对象里得到表里的objectStore对象
          let objectStore = transaction.objectStore(reply)
          let res = this.res
          for (let i =0; i < res.length; i ++) {
            objectStore.add(res[i])
            // console.log(211, "添加成功")
          }
        },

      },
      watch:{
        temp(){
          this.replies = this.temp
        }
      },
      mounted() {
        let _this = this
        let author = this.$store.state.username
        let permlink = this.$route.params.permlink
        let lengthFlag = permlink + 'length'

        async function getHiveReplies(author, permlink, res=[]) {
          //从hive上获取本篇所有的评论
          let replies = await _this.hive.api.getContentRepliesAsync(author, permlink)
          let children = []
          replies.forEach(item => {
            res.push(item)
            if(item.children > 0){
              children.push(getHiveReplies(item.author, item.permlink, res))
            }
          })
          await Promise.all(children)
          return res
        }

        if(localStorage.getItem(lengthFlag) == null){
          //第一次同步，从hive中读取评论并存入数据库中
          async function main(){
            let allReplies = await getHiveReplies(author, permlink)
            _this.res= allReplies
            //把数据整合成递归形式显示
            _this.filterRepliesTemp(author, permlink)
            //设置评论长度标记
            let length = allReplies.length.toString()
            localStorage.setItem(lengthFlag, length)

            //存入数据库
            _this.saveReplies()
            // console.log(233, '初始化评论成功！')
          }
          main()
        }else{
          //直接读数据库
          async function main(){
            _this.firstreplies = await _this.getRepliesFromDB()

            //把数据整合成递归形式显示
            _this.filterRepliesDB(author, permlink)
          }
          setTimeout(main, 200)

          async function repliesAsync(){
            //同步评论
            let allReplies = await getHiveReplies(author, permlink)
            let length = parseInt(localStorage.getItem(lengthFlag))
            if( length < allReplies.length){
              //如果长度标记小于新的评论的长度，则是有新评论
              //把原来的评论删除，重新存入
              _this.res = allReplies
              //把数据整合成递归形式显示
              _this.filterRepliesTemp(author, permlink)

              //更新数据库
              await _this.searchRepliyKeys(allReplies)
              await _this.delreplies()
              _this.saveReplies()
              // console.log(655, '更新评论成功！')

              //更新长度标记
              localStorage.setItem(lengthFlag, allReplies.length.toString() )
            }
          }
          setTimeout(repliesAsync, 300)
        }
      },

      components: {
        Replylist
      }

    }
</script>

<style scoped>
  .reply{
    color: rgba(55,53,58,0.75);
    width: 95%;
    display: block;
  }

</style>
