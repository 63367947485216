<template>
  <div class="login">
    <h2>用户登录</h2>
    <div v-if="flag">
      <b-input
              id="inline-form-input-name"
              class="mb-2 mr-sm-2 mb-sm-0 user"
              placeholder="请输入用户名" v-model="username"
      ></b-input>
      <b-input
              id="inline-form-input-password"
              type="password"
              class="mb-2 mr-sm-2 mb-sm-0 user"
              placeholder="请输入Steem发贴密钥（posting key）" v-model="steempassword" 
      ></b-input>

       <b-input
              type="password"
              class="mb-2 mr-sm-2 mb-sm-0 user"
              placeholder="请输入Hive发贴密钥（posting key）或 密码" v-model="password"  
      ></b-input>

      <div class="loginbtn">
        <b-button variant="outline-primary" @click="login">登录</b-button>
      </div>
    </div>
    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
  </div>
</template>

<script>
  import Loading from './Loading'
  export default {
    name: "Login",
    data() {
      return {
        username:'',
        password:'', 
        steempassword:'', 
        isLoading: false,
        flag: true
      }
    },
    methods:{
      login(){
        //用私钥或密码登录
        this.flag = false
        this.isLoading = true
        let account = this.username
        let privWif = this.password
        let steempassword = this.steempassword

        this.hive.api.getAccounts([account], (err, result) => {
          if(result.length === 0){
            this.isLoading = false
            this.flag = true
            alert("用户不存在！")
          }else{
            //用户存在
            let pubWif = result[0].posting.key_auths[0][0]
						// console.log(456, pubWif, '公钥！')
            let isvalid
            //判断是私钥还是密码
            let res = this.hive.auth.isWif(privWif)
            if(res === true){
              //是私钥
              // console.log(456, res, '是私钥！')
              try{
                isvalid = this.hive.auth.wifIsValid(privWif, pubWif)
              }
              catch(e){
                isvalid = 'false'
              }
              if(isvalid === true){
                this.isLoading = false
                console.log(' Welcome.', account)
                this.$store.commit('saveUser', {username: account, password: privWif, steempassword: steempassword})
                this.$router.push({path:'/blogs'})
              }else{
                this.isLoading = false
                this.flag = true
                alert(`错误！请检查用户名和发贴密钥！`)
              }
            }else{
              //是密码
              // console.log(458, res, '是密码')
              //获取发贴私钥
              let role = ["posting"]
              let Wif = this.hive.auth.toWif(account, privWif, role)
              // console.log(459, 'posting', Wif)
              //
              // let roles = ['owner', 'active', 'posting', 'memo']
              // let ss = this.steem.auth.getPrivateKeys(account, privWif, roles)
              // console.log(460, 'keys', ss)
              try{
                isvalid = this.hive.auth.wifIsValid(Wif, pubWif)
              }
              catch(e){
                isvalid = 'false'
              }
              if(isvalid === true){
                this.isLoading = false
                console.log(' Welcome.', account)
                this.$store.commit('saveUser', {username: account, password: Wif, steempassword: steempassword})
                this.$router.push({path:'/blogs'})
              }else{
                this.isLoading = false
                this.flag = true
                alert(`错误！请检查用户名和密码！`)
              }
            }
          }

        })
      }
    },

    mounted() {
      // let account = "larkio"
      // let privWif = "dk56JJHtwxloiYT51"
      // let roles = ['owner', 'active', 'posting', 'memo']
      // let ss = this.steem.auth.getPrivateKeys(account, privWif, roles)
      // console.log(464, 'keys', ss)
    },

    components: {
      Loading
    },


  }
</script>

<style scoped>

  .login{
    margin: 4rem 4rem 18rem 14rem;

  }
  .user{
    margin-top: 1.5rem;
    width: 50%;
  }
  .loginbtn{
    margin-top: 1.5rem;
  }
</style>

