import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import steem from 'steem'
import hive from '@hiveio/hive-js'
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import {create} from 'ipfs-http-client'


import {getstr} from "./utils/getstr"
import {imgAdd} from "./utils/imgAdd"
import {initDatabase} from "./utils/initDatabase"
import {getAllPosts} from "./utils/getAllPosts"
import {getAllReplies} from "./utils/getAllReplies"
import {getCollections} from "./utils/getCollections"
import {getRepliesToAuthor} from "./utils/getRepliesToAuthor"
import {postToSteem} from "./utils/postToSteem"
import {postToHive} from "./utils/postToHive"
import {saveOneToDb} from "./utils/saveOneToDb"

Vue.use(BootstrapVue)
Vue.use(mavonEditor)


//初始化hive节点
let hiveNode
if(localStorage.getItem("hiveNode") == null){
  hiveNode = 'https://anyx.io'
}else{
  hiveNode = localStorage.getItem("hiveNode")
}
//设置hvie节点, 挂载到全局
hive.api.setOptions({ url: hiveNode})
Vue.prototype.hive = hive

//设置steem节点, 挂载到全局
steem.api.setOptions({ url: 'https://api.steemitdev.com' });
Vue.prototype.steem= steem


//设置ipfs节点, 挂载到全局
const ipfs = create({ host: 'ipfs.ilark.io', port: '9001', protocol: 'https' })
Vue.prototype.ipfs = ipfs


Vue.prototype.getstr = getstr
Vue.prototype.imgAdd = imgAdd
Vue.prototype.initDatabase = initDatabase
Vue.prototype.getAllPosts = getAllPosts
Vue.prototype.getAllReplies = getAllReplies
Vue.prototype.getCollections = getCollections
Vue.prototype.getRepliesToAuthor = getRepliesToAuthor
Vue.prototype.postToSteem = postToSteem
Vue.prototype.postToHive = postToHive
Vue.prototype.saveOneToDb = saveOneToDb

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach(function (to, from, next) {
  if(to.meta.requireAuth){
    // 要去的url只有登陆成功后才能访问
    if (store.state.username) {
      next()
    } else {
      next({name: 'login'})
    }
  }else{
    next()
  }
})

