<template>
  <div id="maintop">

    <b-input
      class="mb-2 mr-sm-2 mb-sm-0 title"
      placeholder="请输入标题" v-model="title"
    ></b-input>

    <div id="main">
      <mavon-editor v-model="body" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
    </div>

    <br>
    <b-input
      id="inline-form-input-name"
      class="mb-2 mr-sm-2 mb-sm-0"
      placeholder="tags  (输入英文标签，以空格分隔)" v-model="tags"
    ></b-input>

    <div class="btn">
      <b-button variant="outline-primary" @click="post">提交</b-button>
    </div>

  </div>
</template>

<script>
    export default {
      name: "Edit",
      data() {
        return {
          db:{},
          posts:[],
          body: '',
          category: '',
          title: '',
          tags: '',
          key: ''
        }
      },

      methods: {
        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        },

        searchKey() {
          let that = this
          let permlink = this.$route.params.permlink
          let db = this.$store.state.db
          // console.log(656, that.db)
          let tableName = this.$store.state.username
          let transaction = db.transaction([tableName], 'readwrite')
          let objectStore = transaction.objectStore(tableName)

          objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
            let cursor = event.target.result
            // console.log(cursor, '游标');
            if (cursor) {
              // console.log(11, cursor.key, cursor.value);
              if (cursor.value.permlink == permlink) {
                that.key = cursor.key
                that.body = cursor.value.body
                that.category = cursor.value.category
                that.title = cursor.value.title

                that.tags = JSON.parse(cursor.value.json_metadata).tags.join(" ")
                // console.log(188, cursor.key)
              }
              cursor.continue()
            }
            //如果游标遍历出现错误
            objectStore.openCursor().onerror = function (event) {
              console.dir(event)
            }
          }
        },

        post() {
          let wif = this.$store.state.password
          let parentAuthor = ''
          let parentPermlink = this.category
          let author = this.$store.state.username
          let permlink = this.$route.params.permlink
          let title = this.title
          let body = this.body
          let tags = this.tags.trim().split(/\s+/)
          let jsonMetadata = {"tags": tags, "dapp": "larkBlog", "format": "markdown"}

          //发布到hive上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

          //发布到本地数据库
          //把数据拼成一个JSON
          let post = {
            "id": this.key,
            "title": title,
            "author": author,
            "category": this.category,
            "permlink": permlink,
            "body": body,
            "created": new Date().toISOString().split('.')[0],
            "json_metadata": JSON.stringify(jsonMetadata)
          }

          let _this = this
          let db = this.$store.state.db
          let tableName = this.$store.state.username

          function newpost(){
            return new Promise(resolve => {
              //得到操作 'testTable' 表的事务对象
              let transaction = db.transaction([tableName], 'readwrite') //可读可写事务
              transaction.oncomplete = function (event) {
                console.log("事务操作完成")
              }
              transaction.onerror = function (event) {
                console.log("事务操作出错")
                console.dir(event)
              }
              transaction.onsuccess = function (event) {
                console.log('事务操作成功')
              }

              //从事务对象里得到表里的objectStore对象
              let objectStore = transaction.objectStore(tableName)
              objectStore.put(post)
              resolve()
            })
          }

          async function main(){
            await newpost()
            let posts = await _this.getAllPosts(db)
            _this.$store.commit('savePosts', posts.reverse())
            //发布完后跳转
            _this.$router.push({path: '/blog/'+permlink})

          }
          main()
        },

      },

      mounted() {
        let _this = this
        async function main(){
          if(_this.$store.state.username){
            //如果已登录
              if(_this.$store.state.posts == false){
                //第一次打开页面或是刷新
                let db = await _this.initDatabase()
                _this.$store.commit('saveDB',db)
                let posts = await _this.getAllPosts(db)
                _this.$store.commit('savePosts', posts.reverse())
                await _this.searchKey()
              }else{
                //从其它页面切换则什么都不用做
                await _this.searchKey()
                // console.log(88,'ok')
              }
            }

        }
        main()
      }

    }

</script>

<style scoped>
  #maintop{
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  #main{
    height: 40rem;
  }
  .title{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem !important;
    width: 60%;
  }
  .btn{
    margin-top: 0.3rem;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }
</style>
