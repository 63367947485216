<template>
  <div class="loading"></div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped>
  .loading {
    margin: 0 auto;
    background: url('../../static/images/loading.gif') center center no-repeat #fff;
    width: 50vw;
    height: 50vh;
    z-index: 1000;
  }
</style>
