<template>
  <div id="content">

    <router-link :to="'/@'+onereply.root_author+'/'+onereply.root_permlink">　
      <h1>原文：{{ onereply.root_title}} </h1>
    </router-link>

    父评论：
    <p>@{{ parentreply.author }}　{{ parentreply.created | formatTime }}</p>
    <mavon-editor
      class="replymd"
      :value="parentreply.body"
      :subfield = "prop.subfield"
      :defaultOpen = "prop.defaultOpen"
      :toolbarsFlag = "prop.toolbarsFlag"
      :editable="prop.editable"
      :boxShadow="false"
      :scrollStyle="prop.scrollStyle"
    ></mavon-editor>


    <div class="child">
    <p>@{{ onereply.author }}　{{ onereply.created | formatTime }}</p>
    <mavon-editor
      class="replymd"
     :value="onereply.body"
    :subfield = "prop.subfield"
    :defaultOpen = "prop.defaultOpen"
    :toolbarsFlag = "prop.toolbarsFlag"
    :editable="prop.editable"
     :boxShadow="false"
    :scrollStyle="prop.scrollStyle"
    ></mavon-editor>
      <span v-if="this.$store.state.username" class="newreply">
      <b-button variant="outline-primary" @click="reply">回复</b-button>
    </span>

      <div v-if="replyFlag">
        <div id="main">
          <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
        </div>

        <b-button variant="outline-primary" @click="replypost">提交</b-button>
        <b-button variant="outline-primary" @click="clear">取消</b-button>
      </div>
    </div>


    <div class="secchild" v-if="newreplyFlag">
      <p>@{{ newreply.author }}　{{ newreply.created | formatTime }}</p>
      <mavon-editor
        class="replymd"
        :value="newreply.body"
        :subfield = "prop.subfield"
        :defaultOpen = "prop.defaultOpen"
        :toolbarsFlag = "prop.toolbarsFlag"
        :editable="prop.editable"
        :boxShadow="false"
        :scrollStyle="prop.scrollStyle"
      ></mavon-editor>
      <b-button variant="outline-primary" @click="edit()">编辑</b-button>
      <div v-if="editFlag">
        <div id="main2">
          <mavon-editor v-model="text" ref="md" @imgAdd="imgAdd" style="height: 100%"/>
        </div>
        <b-button variant="outline-primary" @click="refresh(newreply)">更新</b-button>
        <b-button variant="outline-primary" @click="clear">取消</b-button>
        </div>
      </div>


  </div>
</template>

<script>

  import OtherReply from './OtherReply'
    export default {
      name: "ReplyDetail",
      data() {
        return {
          db:{},
          data:'',
          flag:localStorage.getItem(this.$route.params.permlink),
          replyFlag: false,
          editFlag: false,
          text:'',
          areply:{},
          onereply:{},
          parentreply:{},
          newreply:{},
          newreplyFlag: false,
          tags: 'tags(标签):　'
        }
      },

      methods:{
        reply(){
          this.replyFlag = true
        },

        replypost(){
          this.replyFlag = false
          let wif = this.$store.state.password
          let parentAuthor = this.$route.params.author
          let parentPermlink = this.$route.params.permlink
          let author = this.$store.state.username
          let permlink = this.getstr()
          let title = ''
          let body = this.text
          let tags = ['cn', 'larkBlog']
          let jsonMetadata = {"tags": tags, "dapp": "larkBlog", "format": "markdown"}

          //发布到hive上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

          //把数据拼成一个JSON直接显示在前端
          let reply = {
            "parent_author": parentAuthor,
            "parent_permlink": parentPermlink,
            "title": title,
            "author": author,
            "permlink": permlink,
            "body": body,
            "created": new Date().toISOString().split('.')[0],
            "json_metadata": JSON.stringify(jsonMetadata)
          }
          this.newreply = reply
          this.newreplyFlag = true
          this.text = ''
        },

        edit(){
          this.editFlag = true
          this.text = this.newreply.body
        },

        refresh(newreply){
          this.editFlag = false
          this.$set(newreply, "body", this.text)

          let wif = this.$store.state.password
          let author = this.$store.state.username
          let parentAuthor = newreply.parent_author
          let parentPermlink = newreply.parent_permlink
          let permlink = newreply.permlink
          let title = newreply.title
          let body = this.text
          let jsonMetadata = newreply.json_metadata

          //发布到hvie上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

        },

        clear(){
          this.replyFlag = false
          this.text = ''
        },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }

      },

      watch:{
          flag(newVal){
            return newVal
          }
      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        },

      },

      mounted() {
        let _this = this
        this.hive.api.getContent(this.$route.params.parent_author, this.$route.params.parent_permlink, function(err, result) {
          // console.log(234, err, result)
          _this.parentreply = result
        })
        this.hive.api.getContent(this.$route.params.author, this.$route.params.permlink, function(err, result) {
          // console.log(356, err, result)
          _this.onereply = result
        })


      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      components: {
        OtherReply
      }
    }
</script>

<style scoped>
  h1{
    font-size: 1.5rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
  a{
    color: rgba(55,53,58,0.75);
  }
  a:hover{
    color: darkcyan;
    text-decoration: none;
  }
  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 3rem;
   }
  .replymd{
    min-height: auto;
    box-shadow: transparent;
    margin-bottom: 0.5rem;
  }

  #main{
    width: 95%;
    height: 20rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .child{
    margin-left: 2rem;
  }

  .secchild{
    margin-left: 4rem;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: transparent;
    font-size: 0.7rem;

  }
  .btn-outline-primary:hover{
    background-color: transparent;
    color: darkcyan;
  }


</style>
