<template>
  <div>
      <div class="article">
        <div class="content" v-for="comment in comments">
          <p class="posttime">@{{ comment.author }}　{{ comment.created | formatTime }}</p>
          <router-link :to="'/commentdetail/'+'@'+comment.parent_author+'/'+comment.parent_permlink+'/'+'@'+comment.author+'/'+comment.permlink">
            <mavon-editor
              id="replymd"
              :value="comment.body"
              :subfield = "prop.subfield"
              :defaultOpen = "prop.defaultOpen"
              :toolbarsFlag = "prop.toolbarsFlag"
              :editable="prop.editable"
              :boxShadow="false"
              :scrollStyle="prop.scrollStyle"
            ></mavon-editor>
          </router-link>

         </div>

        <!--加载动画-->
        <transition name="fade">
          <loading v-if="isLoading"></loading>
        </transition>

        <div class="btn">
          <b-button variant="outline-primary" @click="getNext">查看更多</b-button>
        </div>

     </div>

  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
      name: "Comments",
      data() {
        return {
          comments:[],
          permlink:'',
          isLoading: true
          }
      },
      methods: {
        getComments(){
          let that = this
          let author = this.$store.state.username
          let query = { limit : 30, start_author: author, start_permlink: ""}
          this.hive.api.getDiscussionsByComments(query, function(err, result) {
            // console.log(265, err, result)
            that.comments = result
            that.permlink = result[result.length - 1].permlink
            // 加载动画结束
            that.isLoading = false
          })
        },
        getNext(){
          let that = this
          that.isLoading = true
          let author = this.$store.state.username
          that.isLoading = true
          let query = { limit : 30, start_author: author, start_permlink: this.permlink}
          this.hive.api.getDiscussionsByComments(query, function(err, result) {
            result.forEach(post => {
              if (post.permlink != that.permlink) {
                that.comments.push(post)
              }
            })
            that.permlink = result[result.length - 1].permlink
            that.isLoading = false
            if(result.length == 1){
              alert('没有更多了！')
            }
          })
        }

      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      computed: {
        repliesreverse: function () {
          return this.$store.state.repliestoauthor
        },
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      components: {
        Loading
      },

      mounted() {
        this.getComments()

      }

    }

</script>

<style scoped>

  .article{
    margin-top: 2rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 0.5rem auto;
    padding-bottom: 0.5rem;
    width: 70%;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan !important;
  }

  #replymd{
    min-height: auto;
    box-shadow: transparent;
  }

  #replymd2{
    min-height: auto;
    box-shadow: transparent;
  }
  .btn{
    display: block;
    margin-left: 10%;
    margin-bottom: 1rem;
  }
  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }

</style>
