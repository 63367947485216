const imgAdd= function(pos, $file){
    const ipfs_host = "https://ipfs.ilark.io/ipfs/"
    // console.log(223, $file)

    let that = this
    let saveImageOnIpfs = (reader) => {
        return new Promise(function(resolve, reject) {
            const buffer = Buffer.from(reader.result)
            that.ipfs.add(buffer).then((response) => {
                resolve(response.path)
            }).catch((err) => {
                console.error(err)
                reject(err)
            })
        })
    }

    let reader = new FileReader()
    reader.readAsArrayBuffer($file)
    reader.onloadend = function (e) {
        saveImageOnIpfs(reader)
            .then(hash => {
                // console.log(655, hash)
                // console.log(755, that.$refs.md)
                that.$refs.md.$img2Url(pos, ipfs_host+hash)
                // that.$refs.md[0].$img2Url(pos,  ipfs_host+hash)
            })
    }

}

export {imgAdd}
