<template>
  <div>
      <b-input
        class="mb-2 mr-sm-2 mb-sm-0 search"
        placeholder="搜索" v-model="keywords"
      ></b-input>

     <div class="article">
        <div class="content" v-for="post in search(keywords).slice(0, 30)">
              <router-link :to="'/blog/'+post.permlink"><p class="posttime">{{ post.created | formatTime}}</p> 　{{ post.title }}</router-link>
         </div>
        <!--隐藏部分-->
       <div class="content" v-if="postFlag" v-for="post in search(keywords).slice(30)">
         <router-link :to="'/blog/'+post.permlink"><p class="posttime">{{ post.created | formatTime }}</p> 　{{ post.title }}</router-link>
       </div>

       <div class="btn" v-if="nextFlag">
         <b-button variant="outline-primary" @click="getAll">查看全部</b-button>
       </div>
     </div>

    <!--加载动画-->
    <transition name="fade">
      <loading v-if="isLoading"></loading>
    </transition>
     <!--<button @click="getkeys">密钥</button>-->
  </div>
</template>

<script>
  import Loading from './Loading'
    export default {
      name: "Blogs",
      data() {
        return {
          keywords: '',
          postFlag: false,
          nextFlag: true,
          isLoading: false
          }
      },
      methods: {
        getAll(){
          this.postFlag = true
          this.nextFlag = false
        },

        getSteemPosts(author, startPermlink, limit, posts) {
          //传入要查询的作者名，初始标签，一次查询的条数，初始化数组
          let that = this
          return new Promise((resolve, reject) => {
            let beforeDate = new Date().toISOString().split('.')[0]
            that.hive.api.getDiscussionsByAuthorBeforeDate(author, startPermlink, beforeDate, limit, function (err, result) {
              if (result.length > 1) {
                let newResult = []
                result.forEach(post => {
                  if (post.permlink != startPermlink) {
                    newResult.push(post)
                  }
                })
                posts = [...posts, ...newResult]
                that.getSteemPosts(author, result[result.length - 1].permlink, limit, posts)
                  .then(resolve)
                  .catch(reject)
              } else {
                resolve(posts)
              }
            })
          })
        },

        savePosts(db, hiveposts) {
         let tableName = this.$store.state.username
          //得到操作 'testTable' 表的事务对象
          let transaction = db.transaction([tableName], 'readwrite') //可读可写事务
          return new Promise(resolve => {
            transaction.oncomplete = function (event) {
              console.log("111, 事务操作完成")
              resolve()
            }
            transaction.onerror = function (event) {
              console.log("事务操作出错")
              console.dir(event)
            }
            transaction.onsuccess = function (event) {
              console.log('222, 事务操作成功')
            }
            //从事务对象里得到表里的objectStore对象
            let objectStore = transaction.objectStore(tableName)
            for (let i = hiveposts.length - 1; i > -1 ; i--) {
              objectStore.add(hiveposts[i])
              // console.log(223, "添加成功")
            }
            //设置初始化标记
            let initFlag = this.$store.state.username
            localStorage.setItem(initFlag, 'hivejiang')
          })
        },

        search(keywords){
          let posts = this.$store.state.posts
          return posts.filter(item => {
            if(item.title.includes(keywords)){
              // console.log(166, '查到了！')
              return item
            }
          })
        },

          getkeys(){
              let name = 'wikicoin'
              let password = 'kkj254dddew'
              let roles = ['owner', 'active', 'posting', 'memo']
              let res = this.hive.auth.getPrivateKeys(name, password, roles)
              console.log(1234, res)
          }
      },

      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      components: {
        Loading
      },

      mounted() {
        let _this = this
        async function main(){
          if(_this.$store.state.username){
            //如果已登录
            if(localStorage.getItem(_this.$store.state.username) == null){
              console.log(166, '第一次登录')
              _this.isLoading = true
              //如果是第一次登录
              //从hive上获取所有文章并存入vuex
              let hiveposts = await _this.getSteemPosts(_this.$store.state.username, null, 100, [])
              _this.$store.commit('savePosts', hiveposts)
              //初始化数据库并存入
              let db = await _this.initDatabase()
              _this.$store.commit('saveDB',db)
              await _this.savePosts(db, hiveposts)
              _this.isLoading = false

            }else{
              //如果是后续登录
              if(_this.$store.state.posts == false){
                //第一次打开页面或是刷新
                // console.log(566, '第一次打开页面或是刷新')
                let db = await _this.initDatabase()
                _this.$store.commit('saveDB',db)
                let posts = await _this.getAllPosts(db)
                _this.$store.commit('savePosts', posts.reverse())

                //同步文章
                //取得最新文章的时间标记
                let latestPostFlag = posts[0].created

                let permlinkList = []
                posts.forEach(item => {
                  permlinkList.push(item.permlink)
                })

                //从hive上获取最新文章
                let author = _this.$store.state.username
                let beforeDate = new Date().toISOString().split('.')[0]
                let newposts = []
                let startPermlink = null
                while(true){
                  let result = await _this.hive.api.getDiscussionsByAuthorBeforeDateAsync(author, startPermlink, beforeDate, 10)
                  // console.log(655,result)
                  if (latestPostFlag < result[0].created && permlinkList.indexOf(result[0].permlink) === -1 ) {
                    //有新文章并且不是重复的文章
                    result.forEach(post => {
                      if (post.permlink !== startPermlink && permlinkList.indexOf(post.permlink) === -1 ) {
                        newposts.push(post)
                      }
                    })
                   //取到最后一篇文章的Permlink，做为下一次查询的起始点
                    startPermlink = result[result.length - 1].permlink
                    //如果最后一篇文章也是新文章则继续，否则终止while
                    if(latestPostFlag > result[result.length - 1].created){
                      console.log(233, '没有更新的文章')
                      break
                    }
                  }else{
                    // console.log(133, '没有新文章')
                    break
                  }
                }
                if(newposts.length > 0){
                 await  _this.savePosts(db, newposts)
                  console.log(544, '添加新文章成功！')
                  let posts2 = await _this.getAllPosts(db)
                  _this.$store.commit('savePosts', posts2.reverse())
                }
              }
            }
          }else{
            _this.$router.push({path: '/hot'})
          }
        }
        main()
      }

    }

</script>

<style scoped>

  .article{
    margin-top: 3rem;
  }
  .posttime{
    font-size: 0.8rem;
    display: inline;
  }
  .content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    height: 3rem;
    width: 70%;
    border-bottom: 1px solid #ddd;
  }
  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;
  }
  .btn{
    display: block;
    margin-left: 10%;
    margin-bottom: 1rem;
  }
  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }
  .search{
    margin: 1rem auto !important;
    width: 75%;
  }
</style>
