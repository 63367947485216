import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../components/Register'
import Login from '../components/Login'
import Index from '../components/Index'
import Trend from "../components/Trend"
import Post from '../components/Post'
import Category from '../components/Category'
import Edit from '../components/Edit'
import Feeds from '../components/Feeds'
import Feed from "../components/Feed"
import Searchs from "../components/Searchs"
import Blogs from "../components/Blogs"
import Blog from "../components/Blog"
import Article from "../components/Article"
import Collections from "../components/Collections"
import Collection from "../components/Collection"
import Search from "../components/Search"
import ReplyToAuthor from "../components/ReplyToAuthor"
import ReplyDetail from "../components/ReplyDetail"
import Comments from "../components/Comments"
import CommentDetail from "../components/CommentDetail"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/@:author/:permlink',
    name: 'article',
    component: Article,
  },
  {
    path: '/trend/@:author/:permlink',
    name: 'trend',
    component: Trend
  },
  {
    path: '/feeds',
    name: 'feeds',
    component: Feeds,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/searchs',
    name: 'searchs',
    component: Searchs
  },
  {
    path: '/blogs',
    name: 'blogs',
    component: Blogs,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/blog/:permlink',
    name: 'blog',
    component: Blog
  },
  {
    path: '/search/@:author/:permlink',
    name: 'search',
    component: Search
  },
  {
    path: '/collections',
    name: 'collections',
    component: Collections,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/collection/@:author/:permlink',
    name: 'collection',
    component: Collection
  },
  {
    path: '/feed/@:author/:permlink',
    name: 'feed',
    component: Feed
  },
  {
    path: '/category',
    name: 'category',
    component: Category,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },

  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/reply',
    name: 'replytoauthor',
    component: ReplyToAuthor,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/comments',
    name: 'comments',
    component: Comments,
    meta:{
      requireAuth:true
    }
  },

  {
    path: '/replydetail/@:parent_author/:parent_permlink/@:author/:permlink',
    name: 'replydetail',
    component: ReplyDetail
  },
  {
    path: '/commentdetail/@:parent_author/:parent_permlink/@:author/:permlink',
    name: 'commentdetail',
    component: CommentDetail
  },
  {
    path: '/post',
    name: 'post',
    component: Post,
    meta:{
      requireAuth:true
    }
  },
  {
    path: '/edit/:permlink',
    name: 'edit',
    component: Edit,
    meta:{
      requireAuth:true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
