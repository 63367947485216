<template>
  <div id="content">
    <h1>{{ post.title }}</h1>
    <p class="posttime">@{{ post.author }} 　{{ post.created | formatTime }}</p>

    <mavon-editor
      class="md"
      :value="post.body + '<br>' + '<br>' + tags + JSON.parse(post.json_metadata).tags.join('　')"
			:subfield = "prop.subfield"
			:defaultOpen = "prop.defaultOpen"
			:toolbarsFlag = "prop.toolbarsFlag"
			:editable="prop.editable"
			:scrollStyle="prop.scrollStyle"
    ></mavon-editor>

    <br>

    <div v-if="this.$store.state.username">
      <b-button variant="outline-primary" @click="edit(post.permlink)">编辑</b-button>
      <b-button variant="outline-primary"><a target="_blank" :href="'https://hiveblocks.com/@'+this.$store.state.username+'/'+post.permlink">文章数据</a></b-button>


    </div>
    <br>

    <Reply></Reply>

  </div>
</template>

<script>
  import Reply from './Reply'
    export default {
      name: "Blog",
      data() {
        return {
          post: {},
          db:{},
          key:'',
          replies:[],
          tags: 'tags(标签):　'
        }
      },

      methods:{
        edit(permlink){
          this.$router.push({path: '/edit/'+permlink})
        },
        searchPosts(posts){
          let permlink = this.$route.params.permlink
          posts.forEach(item => {
            if(item.permlink == permlink){
              this.post = item
            }
          })
        },

        searchKey() {
          let that = this
          let permlink = this.$route.params.permlink
          let db = this.$store.state.db
          let tableName = this.$store.state.username
          let transaction = db.transaction([tableName], 'readwrite')
          let objectStore = transaction.objectStore(tableName)

          objectStore.openCursor().onsuccess = function (event) {  //使用游标遍历
            let cursor = event.target.result
            // console.log(cursor, '游标');
            if (cursor) {
              // console.log(11, cursor.key, cursor.value);
              if (cursor.value.permlink == permlink) {
                // console.log(666, '查到了666');
                that.key = cursor.key
              }
              cursor.continue()
            }

            //如果游标遍历出现错误
            objectStore.openCursor().onerror = function (event) {
              console.dir(event)
            }
          }
        },

      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      components: {
        Reply
      },
      filters:{
        formatTime(data){
          return data.substring(0,10)
        }
      },

      mounted() {
        let _this = this
        async function main() {
          if(_this.$store.state.username) {
            if (_this.$store.state.posts != false) {
              //直接查找数据
              let posts = _this.$store.state.posts
              _this.searchPosts(posts)
              _this.searchKey()
            } else {
              //刷新则先打开数据库并获得数据
              let db = await _this.initDatabase()
              _this.$store.commit('saveDB', db)
              let posts = await _this.getAllPosts(db)
              _this.$store.commit('savePosts', posts.reverse())
              _this.searchPosts(posts)
              _this.searchKey()
            }
          }
        }
        main()

      },

    }
</script>

<style scoped>
  h1{
    font-size: 2rem;
    margin: 1.5rem auto;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
  }
  #content{
    color: rgba(55,53,58,0.75);
    align: center;
    margin: 1rem auto;
    width: 75%;
    padding-bottom: 0.2rem;
   }
  .posttime{
    text-align: center;
  }

  img {
    vertical-align: middle;
    width: 2rem !important;
    background-color: blueviolet;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;

  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: rgba(55,53,58,0.75);
  }
  .btn-outline-primary:hover{
    background-color: chocolate;
  }
</style>
