const initDatabase = function(){
  let db
  let openRequest
  const dbName = this.$store.state.username
  const tableName = this.$store.state.username
  const collection = this.$store.state.username + 'collection'
  const reply = this.$store.state.username + 'reply'
  const comments = this.$store.state.username + 'comments'
  const replyToAuthor = this.$store.state.username + 'replytoauthor'
  const indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB
  if (!indexedDB) {
    alert("你的浏览器不支持IndexedDB")
  }
  return new Promise(resolve => {
    openRequest = indexedDB.open(dbName, 3) //创建或打开数据库

    //数据库创建或更新事件  创建或版本号升级时会触发，从而新建表
    openRequest.onupgradeneeded = function (event) {
      // console.log('数据库创建或更新')
      db = event.target.result
      // console.log(db.version, '数据库版本')

      if (!db.objectStoreNames.contains(tableName)) { //判断文章表是否存在
        let objectstore = db.createObjectStore(tableName, {keyPath: 'id', autoIncrement: true}) //指定主键 id, 自增
        //新建表索引，IDBObject.createIndex()的三个参数分别为索引名称、索引所在的属性、配置对象（说明该属性是否包含重复的值）。
        objectstore.createIndex('title', 'title', {unique: false})
      }

      if (!db.objectStoreNames.contains(collection)) { //判断收藏表是否存在
        let objectstore = db.createObjectStore(collection, {keyPath: 'id', autoIncrement: true}) //指定主键 id, 自增
        //新建表索引，IDBObject.createIndex()的三个参数分别为索引名称、索引所在的属性、配置对象（说明该属性是否包含重复的值）。
        objectstore.createIndex('title', 'title', {unique: false})
      }

      if (!db.objectStoreNames.contains(reply)) { //判断对文章的回复表是否存在
        let objectstore = db.createObjectStore(reply, {keyPath: 'id', autoIncrement: true}) //指定主键 id, 自增
        //新建表索引，IDBObject.createIndex()的三个参数分别为索引名称、索引所在的属性、配置对象（说明该属性是否包含重复的值）。
        objectstore.createIndex('title', 'title', {unique: false})
      }

      if (!db.objectStoreNames.contains(comments)) { //判断评论表是否存在
        let objectstore = db.createObjectStore(comments, {keyPath: 'id', autoIncrement: true}) //指定主键 id, 自增
        //新建表索引，IDBObject.createIndex()的三个参数分别为索引名称、索引所在的属性、配置对象（说明该属性是否包含重复的值）。
        objectstore.createIndex('title', 'title', {unique: false})
      }

      if (!db.objectStoreNames.contains(replyToAuthor)) { //判断对作者的回复表是否存在
        let objectstore = db.createObjectStore(replyToAuthor, {keyPath: 'id', autoIncrement: true}) //指定主键 id, 自增
      }

    }

    //数据库打开成功(如果数据库已经存在)
    openRequest.onsuccess = function (event) {
      db = event.target.result
      console.log(666, 'db版本号', db.version)
      db.onerror = function (event) {
        //针对此数据库的所有错误的通用错误处理程序
        alert("Database error:" + event.target.errorCode)
        console.dir(event.target)
      }
      resolve(db)
    }

    //数据库打开错误
    openRequest.onerror = function (event) {
      console.log(event, '数据库打开错误')
    }
  })
}

export {initDatabase}
