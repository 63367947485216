<template>
  <div class="item">
    <span class="child"><span class="author">@{{ parentmsg.author}}</span> 　
      <mavon-editor
        class="replymd"
        :value="parentmsg.body"
        :subfield = "prop.subfield"
        :defaultOpen = "prop.defaultOpen"
        :toolbarsFlag = "prop.toolbarsFlag"
        :editable="prop.editable"
        :boxShadow="false"
        :scrollStyle="prop.scrollStyle"
      ></mavon-editor>
    </span>

    <div v-if="parentmsg.author == this.$store.state.username">
      <b-button variant="outline-primary" @click="edit(parentmsg)">编辑</b-button>
      <div v-if="editFlag">
        <div id="main">
          <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
        </div>

        <b-button variant="outline-primary" @click="refresh(parentmsg)">更新</b-button>
        <b-button variant="outline-primary" @click="clear">取消</b-button>
      </div>

    </div>

    <div v-else>
    <b-button variant="outline-primary" @click="reply(parentmsg.author, parentmsg.permlink, parentmsg)">回复</b-button>
    </div>

    <div v-if="replyFlag">
      <div id="main2">
        <mavon-editor v-model="text" ref="md" @imgAdd="uploadimg" style="height: 100%"/>
      </div>

      <b-button variant="outline-primary" @click="replypost(parentmsg.author, parentmsg.permlink, parentmsg)">提交</b-button>
      <b-button variant="outline-primary" @click="clear">取消</b-button>
    </div>

    <ul v-if="parentmsg.child" class="child">
      <OtherReplylist v-for="(item,index) in parentmsg.child"  :parentmsg="item" :key="index"></OtherReplylist>
    </ul>

  </div>

</template>

<script>
    export default {
      name: "OtherReplylist",
      data(){
        return {
          text:'',
          replyFlag: false,
          editFlag: false,
          permlink:'',
          key:''
       }
      },
      methods:{
        reply(author, permlink, parentmsg){
          this.replyFlag = true
          if(parentmsg.children == 0){
            this.$set(parentmsg, "child", [])
            // console.log(178, parentmsg.child)
          }

        },

        replypost(parentAuthor, parentPermlink,  parentmsg){
          this.replyFlag = false
          let wif = this.$store.state.password
          let author = this.$store.state.username
          let permlink = this.getstr()
          let title = ''
          let body = this.text
          let tags = ['cn', 'larkBlog']
          let jsonMetadata = {"tags": tags, "dapp": "larkBlog", "format": "markdown"}

          //把数据拼成一个JSON
          let reply = {
            "parent_author": parentAuthor,
            "parent_permlink": parentPermlink,
            "title": title,
            "author": author,
            "permlink": permlink,
            "body": body,
            "created": new Date().toISOString().split('.')[0],
            "json_metadata": JSON.stringify(jsonMetadata)
          }
          parentmsg.child.push(reply)

          //发布到hive上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)
        },

        edit(parentmsg){
          this.editFlag = true
          this.text = parentmsg.body
        },

        refresh(parentmsg){
          this.editFlag = false
          this.$set(parentmsg, "body", this.text)

          let wif = this.$store.state.password
          let author = this.$store.state.username
          let parentAuthor = parentmsg.parent_author
          let  parentPermlink = parentmsg.parent_permlink
          let permlink = parentmsg.permlink
          let title = parentmsg.title
          let body = this.text
          let jsonMetadata = parentmsg.json_metadata

          //发布到hive上
          this.postToHive(wif, parentAuthor, parentPermlink, author, permlink, title, body, jsonMetadata)

        },

        clear(){
          this.replyFlag = false
          this.editFlag = false
          this.text = ''
        },

        uploadimg(pos, $file){
          this.imgAdd(pos, $file)
        }

      },

      computed: {
        prop () {
          let data = {
            subfield: false,
            defaultOpen: 'preview',
            editable: false,
            toolbarsFlag: false,
            scrollStyle: true
          }
          return data
        }
      },

      props: ['parentmsg']
    }
</script>

<style scoped>
  .item{
    padding: 0.2rem 0.2rem;
    /*border: 1px dashed #ddd;*/
    margin-bottom: 0.2rem;

  }

  .child{
    display: block;

  }

  .author{
    padding: 0.3rem;
    display: block;
  }

  a{
    color: rgba(55,53,58,0.75);
  }

  a:hover{
    color: darkcyan;
    text-decoration: none;

  }

  #main{
    width: 90%;
    height: 20rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .replymd{
    min-height: auto;
    box-shadow: transparent;
  }

  .btn-outline-primary {
    color: rgba(55,53,58,0.75);
    border-color: transparent;
    font-size: 0.7rem;

  }
  .btn-outline-primary:hover{
    background-color: transparent;
    color: darkcyan;
  }

</style>
